if (typeof newrelic != 'undefined') {
    newrelic.setErrorHandler(error => {
        if (error) {
            return shouldIgnoreError(error)
        }
    })

    const shouldIgnoreError = error => {
        const formatError = error.toString().split("\n")[0]
        const shouldIgnoreList = [
            "$ is not defined",
            "Script error.",
            "Can't find variable: $",
            "Bugsnag is not defined"
        ]
        const shouldIgnore = shouldIgnoreList.includes(formatError)
        return shouldIgnore
    }
}

